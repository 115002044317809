<template>
	<div>
		<div class="fs20 fw_b pt20 pb20">创建活动</div>
		<e-form ref="formRef" v-on:handleAvatarSuccess="handleAvatarSuccess" v-on:beforeAvatarUpload="beforeAvatarUpload" class="w50" :formConfList="addActivityFormConfList" />

<!--		<div class="flex_r f_jc_b pt20 pb20">-->
<!--			<span class="fs20 fw_b">活动产品结构</span>-->
<!--			<div>-->
<!--				<el-button>导入意向产品</el-button>-->
<!--				<el-button>下载模板</el-button>-->
<!--			</div>-->
<!--		</div>-->
<!--		<e-table :tableCols="goodsStructTableConf" :dataOrigin="dataOrigin" :showPagination="false">-->
<!--			<template #account1>-->
<!--				<el-select>-->
<!--					<el-option label="1" value="1"></el-option>-->
<!--				</el-select>-->
<!--			</template>-->
<!--			<template #account2>-->
<!--				<el-input type="textarea" :rows="4"></el-input>-->
<!--			</template>-->
<!--			<template #account3>-->
<!--				<el-input></el-input>-->
<!--				<div>~</div>-->
<!--				<el-input></el-input>-->
<!--			</template>-->
<!--			<template #account4>-->
<!--				<el-input></el-input>-->
<!--			</template>-->
<!--			<template #account5>-->
<!--				<el-input type="textarea" :rows="4"></el-input>-->
<!--			</template>-->
<!--			<template #operation>-->
<!--				<el-button type="danger">删除</el-button>-->
<!--			</template>-->
<!--		</e-table>-->
<!--		<div class="tr">-->
<!--			<el-button type="primary" class="tr">继续添加</el-button>-->
<!--		</div>-->

		<div class="tc mt20 mb40">
			<el-button @click="$router.go(-1)">取消</el-button>
			<el-button type="primary" @click="confirm">提交</el-button>
		</div>
	</div>
</template>

<script>
import {addActivityFormConfList, goodsStructTableConf} from '@data/activity'
export default {
	name: 'AddActivity',
	data() {
		return {
			addActivityFormConfList,
			goodsStructTableConf,
			dataOrigin: {
				data: [
					{}
				]
			},
			formData: {},
			origin_url: ''
		}
	},
	created() {
		this.$nextTick(() => {
			let token = localStorage.getItem('token')
			this.$refs.formRef.data = {"auth_token": token};
			let zbtime = this.$route.query.zbtime

			if(zbtime){
				this.$refs.formRef.formData.zbtime = zbtime
			}
			this.$refs.formRef.initValue()
		});

	},
	methods: {
		handleAvatarSuccess(response){
			if (response.code === 4003) {
				// toast.showToast({
				//     mes: '您没有权限操作！',
				//     timeout: 1500,
				//     callback: () => {
				//         router.go(-1);
				//     }
				// });
				this.$message.error('您没有权限操作');

				return false;
			}
			if(response.code == 0){
				this.$message.error(response.msg);
				return
			}
			// let formData = this.$refs.formRef.formData
			this.$message.success(response.msg);
			this.$refs.formRef.imageUrl = response.data
			this.$refs.formRef.imageUrl = response.data.url
			this.origin_url = response.data.origin_url
			this.$refs.formRef.initValue()
		},
		beforeAvatarUpload(){

		},
		confirm(){
			let that = this
			let formData = this.$refs.formRef.formData
			this.post('?server=live.goods.active.handle.post',{
				a_type:formData.activeType,
				zbtime:formData.zbtime,
				starttime:formData.starttime,
				endtime:formData.endtime,
				baotime:formData.baotime,
				pict_url:this.origin_url,
				a_require:formData.a_require,
				content:formData.content,
				title:formData.name}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				that.$router.go(-1)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	}
}
</script>